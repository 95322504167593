import React from "react"
import { graphql } from "gatsby"
import {
  Typography,
  Container,
  Grid,
  Button,
  Box,
  Divider,
} from "@material-ui/core"
import moment from "moment"
import _ from "lodash"

import theme from "../theme"
import LayoutComponent from "../components/layout.component"
import ArticleCardComponent from "../components/articlecard.component"
import SEO from "../components/seo.component"

const Article = ({ data, location }) => {
  return (
    <LayoutComponent>
      <SEO
        title={data.strapiArticle.title}
        description={data.strapiArticle.seo && data.strapiArticle.description}
        keywords={data.strapiArticle.seo && data.strapiArticle.keywords}
        canonical={location.href}
      />
      <Box
        pt={5}
        pb={5}
        style={{ background: theme.palette.background.athensGray }}
      >
        <Container maxWidth="xl">
          <Grid container alignItems="center" spacing={5}>
            <Grid item md={6}>
              <img
                src={data.strapiArticle.picture.publicURL}
                alt={data.strapiArticle.picture.name}
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box display="flex" flexDirection="row" mb={2}>
                <Typography variant="caption" component="p">
                  {moment(data.strapiArticle.createdAt).format("ll")}
                </Typography>
                <Typography
                  variant="caption"
                  component="p"
                  color="textSecondary"
                >
                  .{" "}
                  {data.strapiArticle.category &&
                    data.strapiArticle.category.name}{" "}
                  .
                </Typography>
                <Typography variant="caption" component="p">
                  3 min read
                </Typography>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                {data.strapiArticle.title}
              </Typography>
              <Typography gutterBottom>{data.strapiArticle.excerpt}</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={3} direction="row" justify="space-between">
            <Grid item md={9}>
              <Typography
                variant="body1"
                component="p"
                dangerouslySetInnerHTML={{
                  __html: data.strapiArticle.content,
                }}
                style={{ textAlign: "justify" }}
              />
            </Grid>
            <Grid item md={2}>
              <Typography gutterBottom variant="h6" component="h3">
                Categories
              </Typography>
              <Divider variant="fullWidth" />
              {_.map(data.allStrapiCategory.edges, ({ node }, i) => {
                return (
                  <Box pt={2}>
                    <Typography variant="body1" component="p">
                      {node.name}
                    </Typography>
                  </Box>
                )
              })}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h5" component="h2">
                Related Articles
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined">View All</Button>
            </Grid>
          </Grid>
          <Box pt={5}>
            <Grid container spacing={3} direction="row">
              {_.map(
                _.slice(data.allStrapiArticle.edges, 0, 4),
                ({ node }, i) => {
                  return (
                    <Grid item xs={12} md={3} key={i}>
                      <ArticleCardComponent node={node} />
                    </Grid>
                  )
                }
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
export default Article

export const articleQuery = graphql`
  query ArticleQuery($id: String!) {
    strapiArticle(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      createdAt
      picture {
        publicURL
        name
      }
      tags {
        id
        name
        slug
      }
      category {
        id
        name
        slug
      }
      seo {
        title
        description
        keywords
      }
    }
    allStrapiArticle {
      edges {
        node {
          id
          title
          slug
          content
          excerpt
          createdAt
          picture {
            publicURL
          }
          tags {
            id
            name
            slug
          }
          category {
            id
            name
            slug
          }
        }
      }
    }
    allStrapiCategory {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`
